<template>
  <div class="topbar">
    <header class="main-header" id="header">
      <nav class="navbar navbar-expand-md bg-light navbar-light">
        <div class="collapse navbar-collapse">
          <ul class="navbar-nav left-navbar">
            <li class="nav-item">
              <router-link class="nav-link" :to="{name:'CreateOrder'}">
                {{ $t('Make Order')}}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{name:'Orders'}">
                {{ $t('帳單')}}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{name:'Seats'}">
                {{ $t('座位狀況')}}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{name:'Invoices'}">
                {{ $t('單據')}}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{name:'Balance'}">
                {{ $t('收銀結餘')}}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{name:'Takeout'}">
                {{ $t('外賣')}}
              </router-link>
            </li>
          </ul>
        </div>

        <div class="collapse navbar-collapse" id="collapsibleNavbar">
          <ul class="navbar-nav">
            <div class="navbar-language-switcher">
              <el-dropdown trigger="click" @command="selectLang">
                <span class="el-dropdown-link">
                  <span v-if="langcode === 'en'">
                    English<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <span v-if="langcode === 'zh'">
                    繁體中文<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="en">English</el-dropdown-item>
                  <el-dropdown-item command="zh">繁體中文</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>

            <li class="nav-item">
              <el-dropdown trigger="click" @command="handleAction">
                <span class="el-dropdown-link">
                  User<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="logout">{{ $t('Logout') }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Cookies from 'js-cookie';

export default {
  name: 'Topbar',
  methods:{
    selectLang(langcode){
      Cookies.set('langcode', langcode);
      this.$i18n.locale = langcode;
      this.changeLang(langcode);
      document.title = this.$t('Admin Panel Title');
    },
    handleAction(command){
      switch(command){
        case 'logout':
         this.logout();
        break;
      }
    },
    logout(){
      Cookies.remove('loginToken');
      Cookies.remove('userId');
      this.startLogout();
      this.$router.push('/');
    },
    ...mapActions(['startLogout', 'changeLang']),
  },
  computed: mapState({
   langcode: state => state.langcode,
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style scoped lang="scss">
.topbar{
  position: sticky;
  top: 0;
  z-index: 50;
  width: 100%;
  background-color: #F8F9FA;
  padding-left: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  align-items: center;
  min-height: 50px;
}
.main-header{
  width: 100%;
}
.logo{
  width: 40px;
}
.navbar-placeholder{
  flex: 1;
  color: black;
}
.el-dropdown-link{
  color: black;
  &:hover{
    cursor: pointer;
  }
}
.navbar-language-switcher{
  margin-right: 10px;
}

.left-navbar{
  .nav-item{
    margin-right: 20px;
  }
}

#collapsibleNavbar{
  justify-content: flex-end;
  .nav-item{
    margin-right: 20px;
    &:last-child{
      margin-left: 20px;
    }
  }
  .navbar-nav{
    display: flex;
    align-items: center;
    a{
      color: black;
    }
  }
}
</style>
